import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import SEO from '../components/seo'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  breadcrumbContainer,
  fittingFlexContainer,
  fittingFlexContainerInner,
  listContainer,
  noFadeHeaderContainer,
  workspaceContentWrapper,
  workspaceContentContainer
} from '../styles/workspaceBusinessFit.module.css'
import {
  googleWorkspaceWrapper
} from '../styles/workspace.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Security & Trust" }) {
        edges {
          node {
            workspaceSubpageFieldGroup {
              workspaceHeaderTitle
              workspaceSubtext
              workspaceContent {
                workspaceIcons {
                  sourceUrl
                }
                workspaceDescription
                workspaceHeading
              }
            }
      title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const SecurityAndTrust = ({ data, pageContext }) => {
  const securityData = data.wordPress.pages.edges[0].node
  const securityContents = securityData.workspaceSubpageFieldGroup.workspaceContent
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug

  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains how Google Workspace will help you create secure and flexible workspaces that scale no matter what.'
        title='Security & Trust'
      />
      <div className={googleWorkspaceWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Security & Trust'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={noFadeHeaderContainer}>
            <h1>
              {securityData.workspaceSubpageFieldGroup.workspaceHeaderTitle}
            </h1>
            <p>{parse(securityData.workspaceSubpageFieldGroup.workspaceSubtext)}</p>
          </div>
        </div>
        <div className={workspaceContentWrapper}>
          <div className={workspaceContentContainer}>
            <div>
              {securityContents.map((content, key) => {
                return (
                  <div
                    className={fittingFlexContainer}
                    key={key.toString()}>
                    <img src={content.workspaceIcons.sourceUrl} />
                    <div className={fittingFlexContainerInner}>
                      <h4>{content.workspaceHeading}</h4>
                      <span className={listContainer}>{parse(content.workspaceDescription)}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
SecurityAndTrust.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default SecurityAndTrust
